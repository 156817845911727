<template>
  <phi-page color="#f2f2f2">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />

      <h1>{{ $t("launcher.classroom") }}</h1>
    </div>

    <div class="ui-row --right">
      <year-picker
        class="year-picker ui-native"
        auto-select
        @input="yearId = $event.id; periodId = null"
        :placeholder="$t('PagePersonAcademicGroups.label.Year')"
      ></year-picker>
      
      <academic-period-picker
        class="period-picker"
        v-if="yearId"
        :year-id="yearId"
        v-model="periodId"
        :query="{person:personId}"
        @fetched="onPeriodsFetched"
      ></academic-period-picker>
    </div>

    <academic-groups
      v-if="personId && periodId"
      :person-id="personId"
      :period-id="periodId"
      :view="currentView"
      @click-group="onClickGroup($event)"
    ></academic-groups>
    <ui-empty v-else></ui-empty>
  </phi-page>
</template>

<script>
import YearPicker from '@/modules/v3/components/V3YearPicker/V3YearPicker.vue';
import AcademicPeriodPicker from '@/modules/v3/components/V3AcademicPeriodPicker/V3AcademicPeriodPicker.vue';
import AcademicGroups from '@/modules/v3/components/V3AcademicPersonGroups/V3AcademicPersonGroups.vue';
import { UiField, UiEmpty } from '@/modules/ui/components';

export default {
  components: {
    YearPicker,
    AcademicPeriodPicker,
    AcademicGroups,
    UiField,
    UiEmpty,
  },

  data() {
    return {
      personId: this.$route.params.personId,
      yearId: null,
      periodId: null,
      currentView: 'grid', // list, grid, column (opciones admitidas por AcademicGroups)
    };
  },

  methods: {
    onClickGroup(group) {
      this.$router.push(
        `/people/${this.personId}/classroom/${group.id}/?period=${this.periodId}`
      );
    },

    onPeriodsFetched(categories) {
      if (this.periodId || !categories.length) {
        return;
      }

      let now = Math.floor(new Date().getTime() / 1000);
      let currentPeriod = categories[0].periods.find(
        (p) => p.start_date < now && now < p.end_date
      );
      if (!currentPeriod) {
        currentPeriod = categories[0].periods[0];
      }

      this.periodId = currentPeriod.id;
    },
  },

  i18n: {
    es: {
      'PagePersonAcademicGroups.label.Year': 'Año',
      'PagePersonAcademicGroups.label.Period': 'Periodo',
      'PagePersonAcademicGroups.label.View': 'Vista',

      'PagePersonAcademicGroups.view.List': 'Lista',
      'PagePersonAcademicGroups.view.Grid': 'Cuadrícula',
    },

    en: {
      'PagePersonAcademicGroups.label.Year': 'Year',
      'PagePersonAcademicGroups.label.Period': 'Period',
      'PagePersonAcademicGroups.label.View': 'View',

      'PagePersonAcademicGroups.view.List': 'List',
      'PagePersonAcademicGroups.view.Grid': 'Grid',
    },
  },
};
</script>

<style lang="scss">
.teacher-year-picker {
  border: 0;
  background: transparent;
}

.expanded-toolbar {
  z-index: 3;
}
</style>