export default httpClient => ({

  getAcademicConsolidateStudentCourse(personId, courseId, periodId) {
    return httpClient.hosts.v3.get('/academic/consolidate/student/course', {
      person: personId,
      course: courseId,
      period: periodId,
      __format: 'json'
    });
  }

})